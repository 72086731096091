<template>
    <div class="control">
        <van-list v-model="loading" finished-text="没有更多了" @load="getData" :finished="finished">
            <div class="total-box" v-if="list.length > 0">
               <span>用户数据</span>
                <strong>{{list.length}}</strong>
            </div>
            <div class="item" v-for="item in list" :key="item.id">
                <van-image
                    width="45"
                    height="45"
                    :src="item.headimgurl"
                />
                <div>
                    <div class="name">{{ item.nickname }}</div>
                    <div class="time">注册时间：{{ item.created_at.slice(0, 10) }}</div>
                </div>
            </div>
            <van-empty v-if="finished && list.length === 0"/>
        </van-list>

    </div>
</template>

<script>
export default {
    name: "userList",
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 1,
        }
    },
    methods: {
        getData() {
            axios.get("/qykshop/get_my_promote_user", {
                params: {
                    page: this.page,
                }
            }).then((res) => {
                if (res.code === 1) {
                    this.list.push(...res.data.list)
                    this.page++
                    this.loading = false
                    if (this.list.length >= res.data.total) {
                        this.finished = true
                    }
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.control {
    width: 100%;
    min-height: 100vh;
    padding: 10px 14px;
    background: #F8F8F8;
    box-sizing: border-box;
    .total-box{
        margin-bottom: 15px;
        font-size: 14px;
        color: #b4b4b4;
        strong{
            color: #369867;
            margin-left: 5px;
        }
    }
    .item {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 22px 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .van-image {
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
        }

        .name {
            font-size: 15px;
            font-weight: bold;
            color: #232323;
            margin-bottom: 7px;
        }

        .time {
            font-size: 13px;
            color: #999999;
        }

        & + .item {
            margin-top: 10px;
        }
    }

    .van-skeleton {
        padding: 0;
    }
}
</style>